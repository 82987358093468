import { useState, useEffect, useRef } from "react";
import {
  StyledStats,
  Header,
  Statistic,
  HistoryVisit,
  Input,
} from "../styled/Statistic";
import { GET_HISTORY, GET_STATS_BY_DATES } from "../gql/query/getHistory";
import { GET_INST_OWNER } from "../gql/query/getInstitutionsOfOwner";
import { useQuery, useLazyQuery } from "@apollo/client";
import bar from "../assets/icons/barchart.svg";
import profit from "../assets/icons/profit.svg";
import searchIcon from "../assets/icons/search.svg";
import forward_up from "../assets/icons/forward-up.svg";
import forward_down from "../assets/icons/forward-down.svg";
import {
  History,
  HistoryVisitations,
  StatsDate,
  StatsByDate,
  Institution,
  StatsDateObject,
  BarData,
} from "../interfaces/Statistics";
import { DataComponent, NameCapital, Data } from "../styled/Admin";
import { getTimeDifference } from "../utils/timeDifference";
import { Bar } from "@ant-design/plots";
import { Menu } from "../styled/Admin";
import MenuComponent from "../component/MenuComponent";
import { Main } from "../styled/Client";
import { onScroll } from "../utils/onScroll";
import { useNavigate } from "react-router-dom";
import { InstituitionObject } from "../interfaces/Instituion";
import { getFromLocalStorage } from "../gql/localStorage/store";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  const { t } = useTranslation();

  const months = {
    1: t("jan"),
    2: t("feb"),
    3: t("mar"),
    4: t("apr"),
    5: t("may"),
    6: t("jun"),
    7: t("jul"),
    8: t("aug"),
    9: t("sep"),
    10: t("oct"),
    11: t("nov"),
    12: t("dec"),
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const [separate, setSeparate] = useState<StatsDateObject>(() => {
    return {
      firstDate: `${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}-${
        day < 10 ? "0" + day : day
      }T00:00:00.000Z`,
      secondDate: `${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}-${
        day < 10 ? "0" + day : day
      }T23:59:59.999Z`,
      separateBy: "Hour",
    };
  });
  const [historyVisit, setHistoryVisit] = useState<HistoryVisitations[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const ref = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const [pickedInst, setPickedInst] = useState<InstituitionObject>();
  const [pickedInstHistory, setPickedInstHistory] =
    useState<InstituitionObject>();

  const [ownDate, setOwnDate] = useState(false);

  const { data: instData } = useQuery<Institution>(GET_INST_OWNER);

  const { data: statsData } = useQuery<StatsDate>(GET_STATS_BY_DATES, {
    variables: pickedInst
      ? {
          firstDate: separate.firstDate,
          secondDate: separate.secondDate,
          separateBy: separate.separateBy,
          institutionIds: [pickedInst._id],
        }
      : {
          firstDate: separate.firstDate,
          secondDate: separate.secondDate,
          separateBy: separate.separateBy,
        },
  });

  const [getHis, { data: hisData }] = useLazyQuery<History>(GET_HISTORY);

  const [barChartData, setBarChartData] = useState<StatsByDate[]>([]);
  const [barData, setBarData] = useState<BarData[]>([]);

  const [showInst, setShowInst] = useState(false);
  const [showInstHistory, setShowInstHistory] = useState(false);
  const [showOtherDates, setShowOtherDates] = useState(false);

  const [datePick, setDatePick] = useState<{
    first: string;
    second: string;
  }>({
    first: "",
    second: "",
  });

  const everyWhereRef: any = useRef();
  const dateSortRef: any = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("stats");
  }, []);

  useEffect(() => {
    if (instData) {
      getHis({
        variables: {
          institutionId: pickedInstHistory ? pickedInstHistory._id : "",
          page: page,
        },
      });
    }
  }, [instData, page, pickedInstHistory]);

  useEffect(() => {
    getHistory();
  }, [hisData]);

  useEffect(() => {
    if (search !== "") {
      if (historyVisit.length !== 0) {
        const res = historyVisit.filter((item) =>
          item.client.fullName.toLowerCase().includes(search.toLowerCase())
        );
        setHistoryVisit(res);
      }
    } else if (search.length === 0) {
      setHistoryVisit([]);
      setPage(1);
      getHis({
        variables: {
          institutionId: pickedInstHistory ? pickedInstHistory._id : "",
          page: page,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (pickedInstHistory) {
      const res = historyVisit.filter(
        (inst) => inst.institution._id === pickedInstHistory._id
      );
      setHistoryVisit([...res]);
    }
  }, [pickedInstHistory]);

  useEffect(() => {
    if (statsData) {
      statsData.getStatsByDates.forEach((item) => {
        const res = barChartData.filter((chart) => chart === item);
        if (res.length === 0) {
          barChartData.push(item);
        }
      });
      setBarChartData([...barChartData]);
    }
  }, [statsData]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (everyWhereRef.current && !everyWhereRef.current.contains(e.target)) {
        setShowInst(false);
      }
    };
    document.addEventListener("mousedown", (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener("mousedown", (e) => handleClickOutside(e));
    };
  }, [everyWhereRef]);

  useEffect(() => {
    if (barChartData) {
      for (const data of barChartData) {
        const res = barData.filter(
          (item) =>
            item.date === data.dates[1].value.toString() &&
            item.сессии === data.numOfSessions &&
            (item.Сумма === data.earnings ||
              (item.Сумма === null && data.earnings === 0))
        );
        if (
          res.length === 0 &&
          statsData !== undefined &&
          barData.length < statsData.getStatsByDates.length
        ) {
          barData.push({
            date:
              separate.separateBy === "Month"
                ? `${months[data.dates[1].value]}`
                : separate.separateBy === "Day"
                ? `${data.dates[1].value}.${
                    data.dates[0].value < 10
                      ? "0" + data.dates[0].value
                      : data.dates[0].value
                  }`
                : `${data.dates[1].value}:00`,
            Сумма: data.earnings !== null ? data.earnings : 0,
            сессии: data.numOfSessions,
          });
        }
      }
      if (statsData && barData.length <= statsData.getStatsByDates.length) {
        separate.separateBy === "Hour"
          ? setBarData([
              ...barData.sort((a, b) =>
                parseInt(a.date.split("'")[0]) > parseInt(b.date.split("'")[0])
                  ? 1
                  : -1
              ),
            ])
          : separate.separateBy === "Month"
          ? setBarData([
              ...barData.sort((a, b) => {
                const aKey = Object.keys(months).find(
                  (key) => months[key] === a.date
                );
                const aDate = new Date(year, parseInt(aKey), 1).getTime();
                const bKey = Object.keys(months).find(
                  (key) => months[key] === b.date
                );
                const bDate = new Date(year, parseInt(bKey), 1).getTime();

                return aDate > bDate ? 1 : -1;
              }),
            ])
          : setBarData([
              ...barData.sort((a, b) => {
                const aDate = new Date(
                  year,
                  parseInt(a.date.split(".")[1]) - 1,
                  parseInt(a.date.split(".")[0])
                ).getTime();
                const bDate = new Date(
                  year,
                  parseInt(b.date.split(".")[1]) - 1,
                  parseInt(b.date.split(".")[0])
                ).getTime();

                return aDate >= bDate ? 1 : -1;
              }),
            ]);
      }
    }
  }, [barChartData]);

  useEffect(() => {
    if (datePick.first && datePick.second) changeSeparate("Custom");
  }, [datePick]);

  const changeSeparate = (sep: string) => {
    setBarChartData([]);
    setBarData([]);
    setSeparate(() => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      if (sep === "Custom" && datePick.first && datePick.second) {
        const firstDate = new Date(datePick.first);
        const secondDate = new Date(datePick.second);
        return {
          firstDate: `${firstDate.getFullYear()}-${
            firstDate.getMonth() + 1 < 10
              ? "0" + (firstDate.getMonth() + 1)
              : firstDate.getMonth() + 1
          }-${
            firstDate.getDate() < 10
              ? "0" + firstDate.getDate()
              : firstDate.getDate()
          }T00:00:00.000Z`,
          secondDate: `${secondDate.getFullYear()}-${
            secondDate.getMonth() + 1 < 10
              ? "0" + (secondDate.getMonth() + 1)
              : secondDate.getMonth() + 1
          }-${
            secondDate.getDate() < 10
              ? "0" + secondDate.getDate()
              : secondDate.getDate()
          }T00:00:00.000Z`,
          separateBy: "Day",
        };
      }
      if (sep === "Month") {
        return {
          firstDate: `${year}-01-01T00:00:00.000Z`,
          secondDate: `${year}-12-31T00:00:00.000Z`,
          separateBy: "Month",
        };
      }
      if (sep === "Day") {
        return {
          firstDate: `${year}-${month < 10 ? "0" + month : month}-${
            day < 10 ? "0" + day : day
          }T00:00:00.000Z`,
          secondDate: `${year}-${
            month + 1 < 10 ? "0" + (month + 1) : month + 1
          }-${day < 10 ? "0" + day : day}T00:00:00.000Z`,
          separateBy: "Day",
        };
      }
      return {
        firstDate: `${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}-${
          day < 10 ? "0" + day : day
        }T00:00:00.000Z`,
        secondDate: `${year}-${
          month + 1 < 10 ? "0" + (month + 1) : month + 1
        }-${day < 10 ? "0" + day : day}T23:59:59.999Z`,
        separateBy: "Hour",
      };
    });
  };

  const getHistory = () => {
    if (hisData) {
      hisData.getHistoryOfVisitations.forEach((el) => {
        const res = historyVisit.filter((item) => item._id === el._id);
        if (res.length === 0) {
          historyVisit.push(el);
        }
      });
      setHistoryVisit([...historyVisit]);
    }
  };

  const getOverallSum = () => {
    if (statsData) {
      let sum = 0;
      let sessionSum = 0;
      for (const stats of statsData.getStatsByDates) {
        sum += stats.earnings !== null ? stats.earnings : 0;
        sessionSum += stats.numOfSessions;
      }
      return [sum, sessionSum];
    }
    return [0, 0];
  };

  console.log(instData, historyVisit);
  return (
    <StyledStats
      ref={mainRef}
      onScroll={() => onScroll(mainRef, historyVisit, setPage)}
    >
      <Menu className="menu-bar">
        <MenuComponent />
      </Menu>
      <Main>
        {instData?.getInstitutionsOfOwner[0]._id !==
          "61b317012480cf18f9a12368" && (
          <Header>
            <h2>{t("stats")}</h2>
            <div className="instituion-separate">
              <div className="instituions">
                <div
                  ref={everyWhereRef}
                  className="show-instituions"
                  onClick={() => setShowInst(!showInst)}
                >
                  <div className="everywhere">
                    <span>
                      {pickedInst ? pickedInst.name : t("everywhere")}
                    </span>
                    <img
                      style={{ marginLeft: "6px" }}
                      src={showInst ? forward_down : forward_up}
                      alt=""
                    />
                  </div>
                  {showInst &&
                    instData?.getInstitutionsOfOwner.map((inst) => {
                      if (pickedInst?._id !== inst._id) {
                        return (
                          <div
                            key={inst._id}
                            className="other"
                            onClick={() => {
                              setPickedInst(inst);
                              setShowInst(false);
                            }}
                          >
                            <>{inst.name}</>
                          </div>
                        );
                      }
                    })}
                  {showInst && pickedInst && (
                    <div
                      className="other"
                      onClick={() => {
                        setPickedInst(undefined);
                        setShowInst(false);
                      }}
                    >
                      <span>{t("everywhere")}</span>
                    </div>
                  )}
                </div>
              </div>
              {instData?.getInstitutionsOfOwner[0]._id !==
                "61b317012480cf18f9a12368" && (
                <div className="separate-by default">
                  <div
                    className={`pick-block ${
                      !(datePick.first && datePick.second) &&
                      separate.separateBy === "Month"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      changeSeparate("Month");
                      setDatePick({
                        first: "",
                        second: "",
                      });
                      setOwnDate(false);
                    }}
                  >
                    <span>{t("year")}</span>
                  </div>
                  <div
                    className={`pick-block ${
                      !(datePick.first && datePick.second) &&
                      separate.separateBy === "Day"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      changeSeparate("Day");
                      setDatePick({
                        first: "",
                        second: "",
                      });
                      setOwnDate(false);
                    }}
                  >
                    <span>{t("month")}</span>
                  </div>
                  <div
                    className={`pick-block ${
                      !(datePick.first && datePick.second) &&
                      separate.separateBy === "Hour"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      changeSeparate("Hour");
                      setDatePick({
                        first: "",
                        second: "",
                      });
                      setOwnDate(false);
                    }}
                  >
                    <span>{t("today")}</span>
                  </div>
                  <div className="pick-own-date">
                    <div
                      className={`pick-block ${
                        datePick.first || datePick.second ? "active" : ""
                      }`}
                      onClick={() => {
                        setOwnDate(!ownDate);
                      }}
                    >
                      <span>{ownDate ? "Убрать" : "Выбрать дату"}</span>
                    </div>
                    {ownDate && (
                      <div className="own-date">
                        <input
                          type={"date"}
                          placeholder="Дата"
                          onChange={(e) =>
                            setDatePick({
                              ...datePick,
                              first: e.target.value,
                            })
                          }
                        />
                        <input
                          type={"date"}
                          placeholder="Дата"
                          onChange={(e) =>
                            setDatePick({
                              ...datePick,
                              second: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="separate-by responsive">
                <div
                  ref={dateSortRef}
                  className={`width-100 ${showOtherDates ? "none" : ""}`}
                  onClick={() => setShowOtherDates(!showOtherDates)}
                >
                  <div className="flex j-center a-center">
                    <span>
                      {separate.separateBy === "Month"
                        ? t("year")
                        : separate.separateBy === "Day"
                        ? t("month")
                        : t("today")}
                    </span>
                    <img
                      style={{ marginLeft: "6px" }}
                      src={!showOtherDates ? forward_up : forward_down}
                      alt=""
                    />
                  </div>
                  {showOtherDates && (
                    <>
                      {/* // < className='absolute-options'> */}
                      {separate.separateBy !== "Month" && (
                        <div
                          className={`width-100 margin-top flex j-center`}
                          onClick={() => {
                            changeSeparate("Month");
                            setShowOtherDates(!showOtherDates);
                          }}
                        >
                          <span>{t("year")}</span>
                        </div>
                      )}

                      {separate.separateBy !== "Day" && (
                        <div
                          className={`width-100 margin-top flex j-center`}
                          onClick={() => {
                            changeSeparate("Day");
                            setShowOtherDates(!showOtherDates);
                          }}
                        >
                          <span>{t("month")}</span>
                        </div>
                      )}
                      {separate.separateBy !== "Hour" && (
                        <div
                          className={`width-100 margin-top flex j-center`}
                          onClick={() => {
                            changeSeparate("Hour");
                            setShowOtherDates(!showOtherDates);
                          }}
                        >
                          <span>{t("today")}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Header>
        )}
        {instData?.getInstitutionsOfOwner[0]._id !==
          "61b317012480cf18f9a12368" && (
          <Statistic>
            <div className="barChart">
              <Bar
                data={barData}
                xField={t("sum")}
                yField={"date"}
                autoFit={true}
                yAxis={{ label: { autoHide: true } }}
                scrollbar={{ type: "vertical" }}
                barStyle={{ columnStyle: { radius: [20, 20, 20, 20] } }}
              />
            </div>
            <div className="main-stats">
              <div className="stats-block">
                <div className="stats-icon">
                  <img src={bar} alt="" />
                </div>
                <div className="session-num">
                  <p>{t("sessions")}</p>
                  <h2>{getOverallSum()[1]}</h2>
                </div>
              </div>
              <div className="stats-block">
                <div className="stats-icon">
                  <img src={profit} alt="" />
                </div>
                <div className="profit-num">
                  <p>{t("revenue")}</p>
                  <h2>{getOverallSum()[0]} ₸</h2>
                </div>
              </div>
            </div>
          </Statistic>
        )}

        <HistoryVisit>
          <div className="history-visit">
            <h2>{t("historyVisits")}</h2>
            <div className="inst-search">
              <div className="instituions">
                <div
                  className="show-instituions"
                  onClick={() => setShowInstHistory(!showInstHistory)}
                >
                  <div>
                    <span>
                      {pickedInstHistory
                        ? pickedInstHistory.name
                        : t("everywhere")}
                    </span>
                    <img
                      style={{ marginLeft: "5px" }}
                      src={showInstHistory ? forward_down : forward_up}
                      alt=""
                    />
                  </div>
                  {showInstHistory &&
                    instData?.getInstitutionsOfOwner.map((inst) => {
                      if (inst._id !== pickedInst?._id) {
                        return (
                          <span
                            key={inst._id}
                            className="margin-top"
                            onClick={() => {
                              setShowInstHistory(false);
                              setPickedInstHistory(inst);
                            }}
                          >
                            <span>{inst.name}</span>
                          </span>
                        );
                      }
                    })}
                  {showInstHistory && pickedInstHistory && (
                    <span
                      className=""
                      onClick={() => {
                        setShowInstHistory(false);
                        setPickedInstHistory(undefined);
                      }}
                    >
                      <span>{t("everywhere")}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="search-by-name">
                <img src={searchIcon} alt="" />
                <Input
                  type="text"
                  placeholder={t("searchByName")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </HistoryVisit>
        <Data ref={ref} onScroll={() => onScroll(ref, historyVisit, setPage)}>
          {historyVisit.map((item) => {
            console.log(item);
            const id = item.client?._id;
            const shortId = id
              ? id.substr(1, 5).toUpperCase() +
                "-" +
                id.substr(id.length - 2).toUpperCase()
              : null;

            const endDate = new Date().toISOString();

            const startDate = new Date(item.startTime);
            const hours = startDate.getHours();
            const minutes = startDate.getMinutes();
            const startTime = `${hours < 10 ? "0" + hours : hours}:${
              minutes < 10 ? "0" + minutes : minutes
            }`;
            const num =
              item.client && item.client.phoneNumber
                ? `+${
                    item.client.phoneNumber[0]
                  } ${item.client.phoneNumber.substr(
                    1,
                    3
                  )} ${item.client.phoneNumber.substr(
                    4,
                    3
                  )} ${item.client.phoneNumber.substr(
                    7,
                    2
                  )} ${item.client.phoneNumber.substr(9, 2)}`
                : "";

            const timeDiff = getTimeDifference(
              item.endTime ? item.endTime : endDate,
              item.startTime
            )[0];
            return (
              <DataComponent
                key={item._id}
                className="data-component"
                onClick={() =>
                  navigate(`/admin/${item._id}`, { state: { ...item.client } })
                }
              >
                <div className="leed-show">
                  <div className="company-name">
                    <div className="institution-name-header">
                      <span>{item.institution.name}</span>
                    </div>
                    <div className="start-time">
                      <span>
                        {startTime}{" "}
                        {`${
                          startDate.getDate() < 10
                            ? "0" + startDate.getDate()
                            : startDate.getDate()
                        }.${
                          startDate.getMonth() + 1 < 10
                            ? "0" + (startDate.getMonth() + 1)
                            : startDate.getMonth() + 1
                        }.${startDate.getFullYear()}`}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="leed">
                    <div className="personal-data">
                      <div className="data">
                        {item.client?.photoURL ? (
                          <img
                            width={"53px"}
                            height={"53px"}
                            style={{
                              borderRadius: "50%",
                              margin: "0 15px",
                            }}
                            src={item.client?.photoURL.XL}
                          />
                        ) : (
                          <NameCapital
                            active={item.status === "Ongoing" ? true : false}
                          >
                            {item.client
                              ? item.client?.fullName[0].toUpperCase()
                              : ""}
                          </NameCapital>
                        )}
                        <div className="client-name">
                          <h2>{item.client ? item.client.fullName : ""}</h2>
                          <p>{num}</p>
                        </div>
                      </div>
                      <div className="instituion-name">
                        <div className="short-id">{shortId}</div>
                        <div>{item.institution.name}</div>
                        <div>{item.service?.name}</div>
                      </div>
                    </div>
                    <div className="time-spent">
                      <div className="money-spent">
                        <div>
                          <h2>{timeDiff}</h2>
                        </div>
                        <div>
                          <h2>{item.price}₸</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="time-spent-responsive">
                  <div className="money-spent">
                    <div>
                      <h2>{timeDiff}</h2>
                    </div>
                    <div>
                      <h2>{item.price}₸</h2>
                    </div>
                  </div>
                </div>
              </DataComponent>
            );
          })}
        </Data>
      </Main>
    </StyledStats>
  );
};

export default Statistics;
